import React, { useState } from "react";
import { Accordion, Col, Collapse, Row } from "reactstrap";
import { BlockTitle, PreviewCard } from "../../../components/Component";
import { Link } from "react-router-dom";
import about from "../../../jsonfiles/About.json";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";

const AccordianSection = ({ className, variation }) => {
  const [isOpen, setIsOpen] = useState("2");
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  const activeVariable = getActiveEnvironmentVariable();

  return (
    <>
      <Row className="g-0 align-center h-100">
        <Col md={6}>
          <div className="h-100 justify-center flex-column p-5">
            <BlockTitle className="mb-3">
              {about?.[activeVariable]?.section3?.map((item) => item?.question1)}
            </BlockTitle>
            <div className="mb-3">
              At{" "}
              <Link to={`${process.env.REACT_APP_PUBLIC_URL}`}>
                <span className="text-primary fw-medium" style={{ cursor: "pointer" }}>
                  {process.env.REACT_APP_TITLE}{" "}
                </span>{" "}
              </Link>
              {about?.[activeVariable]?.section3?.map((item) => item?.answer1)}
            </div>
          </div>
        </Col>
        <Col md={6}>
          <PreviewCard className="border-0 justify-center h-450px">
            <div
              className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}
            >
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("2")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "2" ? "title text-primary" : "title"}>
                    {about?.[activeVariable]?.section3?.map((item) => item?.question2)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                  <div className="accordion-inner">
                    <p>{about?.[activeVariable]?.section3?.map((item) => item?.answer2)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "7" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("7")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "7" ? "title text-primary" : "title"}>
                    {about?.[activeVariable]?.section3?.map((item) => item?.question3)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "7" ? true : false}>
                  <div className="accordion-inner">
                    <p>{about?.[activeVariable]?.section3?.map((item) => item?.answer3)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("1")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "1" ? "title text-primary" : "title"}>
                    {about?.[activeVariable]?.section3?.map((item) => item?.question4)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                  <div className="accordion-inner">
                    <p>{about?.[activeVariable]?.section3?.map((item) => item?.answer4)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("3")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "3" ? "title text-primary" : "title"}>
                    {about?.[activeVariable]?.section3?.map((item) => item?.question5)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                  <div className="accordion-inner">
                    <p>{about?.[activeVariable]?.section3?.map((item) => item?.answer5)}</p>
                  </div>
                </Collapse>
              </div>
            </div>
          </PreviewCard>
        </Col>
      </Row>
    </>
  );
};

export default AccordianSection;
