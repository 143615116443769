import Cookies from "js-cookie";

function getCookie(name) {
  const cookieString = document.cookie;
  const cookies = cookieString.split("; ").reduce((acc, cookie) => {
    const [key, value] = cookie.split("=");
    acc[key] = value ? decodeURIComponent(value) : "";
    return acc;
  }, {});

  return cookies[name] || null;
}

function parseJSON(data) {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
}

function CookieSiteSettings() {
  const siteSettingsString = getCookie("site_Setting");

  if (!siteSettingsString) {
    console.warn("Site settings cookie not found.");
    return null;
  }

  const siteSettings = parseJSON(siteSettingsString);
  if (siteSettings) {
    return siteSettings;
  }

  return null;
}

export default CookieSiteSettings;
