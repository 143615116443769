import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc, setDoc, updateDoc, increment } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";


// Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCc3Oc9M0REka_su6pPmajii6KhtO3c6nA",
//   authDomain: "ipdm-e0d90.firebaseapp.com",
//   projectId: "ipdm-e0d90",
//   storageBucket: "ipdm-e0d90.firebaseapp.com",
//   messagingSenderId: "257434776601",
//   appId: "1:257434776601:web:5a6d7d2bc7f501872f6688",
//   measurementId: "G-W7XTZHFCKF"
// };
const firebaseConfig = {
  apiKey: "AIzaSyAo1zc0PkxX_hsr5Mv3wqF_u4kWCX1cACU",
  authDomain: "ipdm-dj-c54ab.firebaseapp.com",
  projectId: "ipdm-dj-c54ab",
  storageBucket: "ipdm-dj-c54ab.firebasestorage.app",
  messagingSenderId: "880557143276",
  appId: "1:880557143276:web:3b647d4dfce46f2f7561c9",
  measurementId: "G-LYSJD0B86V"
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };