import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import privacypolicy from "../../jsonfiles/Privacypolicy.json";
import rf from "../../jsonfiles/RefundCancellation.json";

const TermsCondition = () => {
  const activeVariable = getActiveEnvironmentVariable();
  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>
            {privacypolicy?.[activeVariable]?.section1?.[0]?.title1}
          </Card.Title>
          <p>
            At <strong>{privacypolicy?.[activeVariable]?.section2?.[1]?.title2}</strong>
            {privacypolicy?.[activeVariable]?.section1?.[2]?.text1}
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h5> {privacypolicy?.[activeVariable]?.section2?.[0]?.listtitle1}</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6> {privacypolicy?.[activeVariable]?.section2?.[1]?.listtext1}</h6>
                  <ul>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section2?.[2]?.listinfotitle1}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section2?.[3]?.listinfotext1}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section2?.[4]?.listinfotitle2}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section2?.[5]?.listinfotext2}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section2?.[6]?.listinfotitle3}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section2?.[7]?.listinfotext3}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section2?.[8]?.listinfotitle4}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section2?.[9]?.listinfotext4}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section2?.[10]?.listinfotitle5}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section2?.[11]?.listinfotext5}
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section3?.[0]?.listtext2}</h6>
                  <ul>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section3?.[1]?.listinfotitle1}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section3?.[2]?.listinfotext1}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section3?.[3]?.listinfotitle2}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section3?.[4]?.listinfotext2}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section3?.[5]?.listinfotitle3}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section3?.[6]?.listinfotext3}
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section4?.[0]?.listtext3}</h6>
                  <ul>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section4?.[1]?.listinfotitle1}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section4?.[2]?.listinfotext1}
                    </li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section5?.[0]?.listtitle2}</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section5?.[1]?.listtext1}</h6>
                  <ul>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section5?.[2]?.listinfotitle1}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section5?.[3]?.listinfotext1}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section5?.[4]?.listinfotitle2}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section5?.[5]?.listinfotext2}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section5?.[6]?.listinfotitle3}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section5?.[7]?.listinfotext3}
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section6?.[0]?.listtext2}</h6>
                  <ul>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section6?.[1]?.listinfotitle1}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section6?.[2]?.listinfotext1}
                    </li>
                    <li>
                      <b>{privacypolicy?.[activeVariable]?.section6?.[3]?.listinfotitle2}</b>{" "}
                      {privacypolicy?.[activeVariable]?.section6?.[4]?.listinfotext2}
                    </li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section7?.[0]?.listtext3}</h6>
                  <ul>
                    <li>{privacypolicy?.[activeVariable]?.section7?.[1]?.listinfotext1}</li>
                    <li>{privacypolicy?.[activeVariable]?.section7?.[1]?.listinfotext1}</li>
                  </ul>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section8?.[0]?.listtext4}</h6>
                  <ul>
                    <li>{privacypolicy?.[activeVariable]?.section8?.[1]?.listinfotext1}</li>
                    <li>{privacypolicy?.[activeVariable]?.section8?.[2]?.listinfotext2}</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section9?.[0]?.listtitle3}</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section9?.[1]?.listtext1}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section9?.[2]?.listinfodes1}</p>
                  <ul>
                    <li>{privacypolicy?.[activeVariable]?.section9?.[3]?.listinfotext1}</li>
                    <li>{privacypolicy?.[activeVariable]?.section9?.[4]?.listinfotext2}</li>
                    <li>{privacypolicy?.[activeVariable]?.section9?.[5]?.listinfotext3}</li>
                    <li>{privacypolicy?.[activeVariable]?.section9?.[6]?.listinfotext4}</li>
                  </ul>
                  <p>{privacypolicy?.[activeVariable]?.section9?.[7]?.listinfodes2}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section10?.[0]?.listtext2}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section10?.[1]?.listinfodes1}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section11?.[0]?.listtext3}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section11?.[1]?.listinfodes1}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section12?.[0]?.listtext4}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section12?.[1]?.listinfodes1}</p>
                  <ul>
                    <li>{privacypolicy?.[activeVariable]?.section12?.[2]?.listinfotext1}</li>
                    <li>{privacypolicy?.[activeVariable]?.section12?.[3]?.listinfotext2}</li>
                    <li>{privacypolicy?.[activeVariable]?.section12?.[4]?.listinfotext3}</li>
                    <li>{privacypolicy?.[activeVariable]?.section12?.[5]?.listinfotext4}</li>
                  </ul>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section13?.[0]?.listtitle4}</h5>
              <p>{privacypolicy?.[activeVariable]?.section13?.[1]?.listinfodes1}</p>
              <ul>
                <li>{privacypolicy?.[activeVariable]?.section13?.[2]?.listinfotext1}</li>
                <li>{privacypolicy?.[activeVariable]?.section13?.[3]?.listinfotext2}</li>
                <li>{privacypolicy?.[activeVariable]?.section13?.[4]?.listinfotext3}</li>
              </ul>
              <p>{privacypolicy?.[activeVariable]?.section13?.[5]?.listinfotext4}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section14?.[0]?.listtext5}</h5>
              <p>{privacypolicy?.[activeVariable]?.section14?.[1]?.listinfodes1}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section15?.[0]?.listtext6}</h5>
              <ListGroup>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[1]?.listinfotitle1}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[2]?.listinfotext1}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[3]?.listinfotitle2}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[4]?.listinfotext2}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[5]?.listinfotitle3}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[6]?.listinfotext3}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[7]?.listinfotitle4}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[8]?.listinfotext4}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[9]?.listinfotitle5}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[10]?.listinfotext5}</p>
                </ListGroup.Item>
                <ListGroup.Item>
                  <h6>{privacypolicy?.[activeVariable]?.section15?.[11]?.listinfotitle6}</h6>
                  <p>{privacypolicy?.[activeVariable]?.section15?.[12]?.listinfotext6}</p>
                </ListGroup.Item>
              </ListGroup>
            </ListGroup.Item>

            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section16?.[0]?.listtext7}</h5>
              <p>{privacypolicy?.[activeVariable]?.section16?.[1]?.listinfodes1}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section17?.[0]?.listtext8}</h5>
              <p>
                {privacypolicy?.[activeVariable]?.section17?.[1]?.listinfodes1}

                <strong>{privacypolicy?.[activeVariable]?.section17?.[2]?.country}</strong>
                {privacypolicy?.[activeVariable]?.section17?.[3]?.listinfodes2}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section18?.[0]?.listtext9}</h5>
              <p>{privacypolicy?.[activeVariable]?.section18?.[1]?.listinfodes1}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section19?.[0]?.listtext10}</h5>
              <p>{privacypolicy?.[activeVariable]?.section19?.[1]?.listinfodes1}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section20?.[0]?.listtext11}</h5>
              <p>{privacypolicy?.[activeVariable]?.section20?.[1]?.listinfodes1}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h5>{privacypolicy?.[activeVariable]?.section21?.[0]?.listtext12}</h5>
              <p>{privacypolicy?.[activeVariable]?.section21?.[1]?.listinfodes1}</p>
              <ul>
                <li>
                  <b>{privacypolicy?.[activeVariable]?.section21?.[2]?.listinfotitle1}</b> <a href={`mailto:${privacypolicy?.[activeVariable]?.section21?.[3]?.listinfotext1}`}>
                  {" "}{ rf?.[activeVariable]?.section7?.map((item) => item?.title4)} </a>
                </li>
                <li>
                <b>{privacypolicy?.[activeVariable]?.section21?.[4]?.listinfotitle2}</b> <a href="tel:+14695540310">{ rf?.[activeVariable]?.section7?.map((item) => item?.title6)}</a>
                </li>
                {/* <li>
                <b>{privacypolicy?.[activeVariable]?.section21?.[6]?.listinfotitle3}</b>  {privacypolicy?.[activeVariable]?.section21?.[7]?.listinfotext3}
                </li> */}
              </ul>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
