import React, { useState } from "react";
import { Block, BlockTitle, PreviewCard } from "../../components/Component";
import { Collapse } from "reactstrap";
import Content from "../../layout/content/Content";
import djonly from "../../jsonfiles/Djonly.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const FAQ_DJ = ({ className, variation }) => {
  const [isOpen, setIsOpen] = useState("0");
  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };
  const activeVariable = getActiveEnvironmentVariable();

  return (
    <div id="check" className=" border">
      <Block size="lg">
        <Content className="p-0">
          <BlockTitle page className="mb-2 text-primary" tag="h3">
            <span>{djonly?.[activeVariable]?.section4?.map((item) => item?.title)}</span>
          </BlockTitle>
          <PreviewCard className="border-0">
            <div
              className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]}
            >
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("2")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "2" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question1)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer1)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "7" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("7")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "7" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question2)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "7" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer2)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("1")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "1" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question3)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer3)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "3" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("3")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "3" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question4)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "3" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer4)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "5" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("5")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "5" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question5)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "5" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer5)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "6" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("6")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "6" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question6)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "6" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer6)}</p>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <div
                  className={[`accordion-head${isOpen !== "8" ? " collapsed" : ""}`]}
                  onClick={() => toggleCollapse("8")}
                  style={{ cursor: "pointer" }}
                >
                  <h6 className={isOpen === "8" ? "title text-primary" : "title"}>
                    {djonly?.[activeVariable]?.section4?.map((item) => item?.question7)}
                  </h6>
                  <span className="accordion-icon"></span>
                </div>
                <Collapse className="accordion-body" isOpen={isOpen === "8" ? true : false}>
                  <div className="accordion-inner">
                    <p>{djonly?.[activeVariable]?.section4?.map((item) => item?.answer7)}</p>
                  </div>
                </Collapse>
              </div>
            </div>
          </PreviewCard>
        </Content>
      </Block>
    </div>
  );
};

export default FAQ_DJ;
