import React from "react";
import { Col, Row } from "reactstrap";
import CityMap from "./CityMap";

const MapView = ({ profiles, city }) => {
  return (
    <Row className="g-0">
      <Col md={12} className="vh-100">
        <CityMap profiles={profiles} city={city} />
      </Col>
    </Row>
  );
};

export default MapView;
