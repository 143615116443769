import contactus1 from "../WhiteLabelimages/contactus2.jpg";
import contactus2 from "../WhiteLabelimages/contactus1.jpg";
import contactus3 from "../WhiteLabelimages/rolesection1.jpg"
import contactus4 from "../WhiteLabelimages/rolesection2.jpg"
import homeimage1 from "../WhiteLabelimages/homeimage1.jpg"
import homevideo1 from '../WhiteLabelimages/homevideo1.mp4'
import homevideo2 from '../WhiteLabelimages/homevideo2.mp4'
import aboutvideo5 from '../WhiteLabelimages/aboutvideo5.mp4'
import aboutvideo2 from '../WhiteLabelimages/aboutvideo2.mp4'
import aboutvideo4 from '../WhiteLabelimages/aboutvideo4.mp4'
import aboutvideo1 from '../WhiteLabelimages/aboutvideo1.mp4'
import aboutvideo3 from '../WhiteLabelimages/aboutvideo3.mp4'
import businessimage1 from '../WhiteLabelimages/website development.jpg'
import businessimage2 from '../WhiteLabelimages/digitalmarketing.jpg'
import businessimage3 from '../WhiteLabelimages/mobileapp.jpg'
import businessimage4 from '../WhiteLabelimages/graphic.jpg'
import businessimage5 from '../WhiteLabelimages/video-editing.jpg'
import placeHolderImg from '../WhiteLabelimages/profile_picture.png'
import client from '../WhiteLabelimages/clients.png'
import dj from '../WhiteLabelimages/dJscopy.png'
import logo from '../images/dark-logo.png'


export const Links = {
  
    "CRM" : {
      "Home" : [
        {"id1": "1",
         "type": "image", "url1": homeimage1, "alt1": "DJ Section",
        "id2": "2", "type": "video", "url2":homevideo1 ,
         "id3": "3", "type": "video", "url3": homevideo2,
        "type1":"image", "url4":placeHolderImg,
        "type1":"image", "url5":logo,
        "type":"image", "url6":client,
        "type":"image", "url7":dj,

      }
      ],
      "About" : [
        { "id1": "1", "type1": "video1", "url1": aboutvideo5,
          "id2": "2", "type2": "video2", "url2": aboutvideo2,
          "id3": "3", "type3": "video3", "url3": aboutvideo4,
          "id4": "4", "type4": "video4", "url4": aboutvideo1,
          "id5": "5", "type5": "video5", "url5": aboutvideo3,
          "id6": "6", "type6": "image1", "url6": contactus3, alt: "",
          "id7": "7", "type7": "image2", "url7": contactus4, alt:""},
      ],
      "Contact": [
        { "id1": "1", "type1": "image", "url1": contactus1, "alt": "HeroSectionImage" ,
         "id2": "2", "type2": "image", "url2": contactus2, "alt": "",
         "id3": "3", "type3": "image", "url3": contactus3, "alt": "",
         "id4": "4", "type4": "image", "url4": contactus4, "alt": ""}
      ],
      "BusinessPortal": [
        { "id": "1", "type": "image", "url1": businessimage1, "alt": ""},
        { "id": "2", "type": "image", "url2": businessimage2, "alt": ""},
        { "id": "3", "type": "image", "url3": businessimage3, "alt": ""},
        { "id": "4", "type": "image", "url4": businessimage4, "alt": ""},
        { "id": "5", "type": "image", "url5": businessimage5, "alt": ""},
      ]
    },
    "photography" : {
      "Home" : [
        {"id1": "1",
         "type": "image", "url1": homeimage1, "alt1": "DJ Section",
        "id2": "2", "type": "video", "url2":homevideo1 ,
         "id3": "3", "type": "video", "url3": homevideo2,
        "type1":"image", "url4":placeHolderImg,
        "type1":"image", "url5":logo}
      ],
      "About" : [
        { "id1": "1", "type1": "video1", "url1": aboutvideo5 ,
          "id2": "2", "type2": "video2", "url2": aboutvideo2,
          "id3": "3", "type3": "video3", "url3": aboutvideo4,
          "id4": "4", "type4": "video4", "url4": aboutvideo1,
          "id5": "5", "type5": "video5", "url5": aboutvideo3,
          "id6": "6", "type6": "image1", "url6": contactus3, alt: "",
          "id7": "7", "type7": "image2", "url7": contactus4, alt:""},
      ],
      "Contact": [
        { "id1": "1", "type1": "image", "url1": contactus1, "alt1": "HeroSectionImage" ,
         "id2": "2", "type2": "image", "url2": contactus2, "alt": "",
         "id3": "3", "type3": "image", "url3": contactus3, "alt": "",
         "id4": "4", "type4": "image", "url4": contactus4, "alt": ""}
      ],
      "BusinessPortal": [
        { "id": "1", "type": "image", "url1": businessimage1, "alt": ""},
        { "id": "2", "type": "image", "url2": businessimage2, "alt": ""},
        { "id": "3", "type": "image", "url3": businessimage3, "alt": ""},
        { "id": "4", "type": "image", "url4": businessimage4, "alt": ""},
        { "id": "5", "type": "image", "url5": businessimage5, "alt": ""},
      ]
    }
    
}
;
