import React, { useEffect, useState, useRef } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import HTMLFlipBook from "react-pageflip";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import abmPanel from "../../../Wed Album - Flipbook by SALU MON _ FlipHTML5.pdf";

import {
  HiChevronDoubleLeft,
  HiChevronDoubleRight,
  HiChevronLeft,
  HiChevronRight,
} from "react-icons/hi";
import { IoIosMenu } from "react-icons/io";
import { MdZoomIn, MdZoomOut, MdFullscreen } from "react-icons/md";
import { BiExitFullscreen } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import { IoMdVolumeHigh } from "react-icons/io";
import { FaVolumeMute } from "react-icons/fa";
import { FaRegPlayCircle } from "react-icons/fa";
import { FaCirclePause } from "react-icons/fa6";
import { BsGridFill } from "react-icons/bs";
import { Button } from "reactstrap";

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

export default function Flipbook() {
  const [pdfPages, setPdfPages] = useState([]);
  const [pageDimensions, setPageDimensions] = useState({
    width: null,
    height: null,
  });
  const [progress, setProgress] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const [autoPlayInterval, setAutoPlayInterval] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const flipbookRef = useRef(null);

  // const flipSound = useRef(new Audio(flipSoundFile));

  const handleFirstPage = () => {
    if (flipbookRef.current) {
      // if (!isMuted) flipSound.current.play();
      flipbookRef.current.pageFlip().flip(0);
    }
  };

  const handleLastPage = () => {
    if (flipbookRef.current) {
      // if (!isMuted) flipSound.current.play();
      flipbookRef.current.pageFlip().flip(pdfPages.length - 1);
    }
  };

  const handlePreviousPage = () => {
    if (flipbookRef.current) {
      // if (!isMuted) flipSound.current.play();
      flipbookRef.current.pageFlip().flipPrev();
    }
  };

  const handleNextPage = () => {
    if (flipbookRef.current) {
      // if (!isMuted) flipSound.current.play();
      flipbookRef.current.pageFlip().flipNext();
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleThumbnailClick = (pageIndex) => {
    if (flipbookRef.current) {
      // if (!isMuted) flipSound.current.play();
      flipbookRef.current.pageFlip().flip(pageIndex);
    }
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.1));
  };

  const handleAutoPlay = () => {
    if (isAutoPlaying) {
      clearInterval(autoPlayInterval);
      setIsAutoPlaying(false);
    } else {
      const interval = setInterval(() => {
        // if (!isMuted) flipSound.current.play();
        if (
          flipbookRef.current.pageFlip().getCurrentPageIndex() <
          pdfPages.length - 1
        ) {
          flipbookRef.current.pageFlip().flipNext();
        } else {
          clearInterval(interval);
          setIsAutoPlaying(false);
        }
      }, 2000);
      setAutoPlayInterval(interval);
      setIsAutoPlaying(true);
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = abmPanel;
    link.download = "flipbook.pdf";
    link.click();
  };

  const handleEnterFullscreen = () => {
    document.documentElement.requestFullscreen();
    setIsFullscreen(true);
  };

  const handleExitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setIsFullscreen(false);
  };

  const toggleMute = () => {
    setIsMuted((prevMute) => !prevMute);
  };

  const renderPDF = async () => {
    const loadingTask = getDocument(abmPanel);
    const pdf = await loadingTask.promise;

    const pages = [];

    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 0.7 });
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      pages.push(canvas.toDataURL());

      if (pageNum === 1) {
        setPageDimensions({
          width: viewport.width,
          height: viewport.height,
        });
      }

      const currentProgress = Math.floor((pageNum / pdf.numPages) * 100);
      setProgress(currentProgress);
    }

    setPdfPages(pages);
  };
  
  

  useEffect(() => {
    renderPDF();
    return () => {
      clearInterval(autoPlayInterval);
    };
  }, []);

  return (
    <>
      <div>
        <div
          className="flipbook"
        
        >
          {/* <h1 className="text-center">Flipbook</h1> */}
          <div className="d-flex">
            {isSidebarOpen && (
              <div className="relative">
                <div
                  className={`fixed top-0 left-0 h-25 bg-gray-800 p-4  transition-transform duration-300 ${
                    isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                  }`}
                 
                >
                  {pdfPages.map((pageImage, index) => (
                    <div
                      key={index}
                      className="mb-2 cursor-pointer"
                      onClick={() => handleThumbnailClick(index)}
                    >
                      <img
                        src={pageImage}
                        alt={`Page ${index + 1}`}
                        className="w-full h-auto border-2 border-white hover:border-yellow-500"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flipbook-wrapper">
              {pdfPages.length > 0 ? (
                <HTMLFlipBook
                  ref={flipbookRef}
                  width={pageDimensions.width}
                  height={pageDimensions.height}
                  showCover={true}
                  onFlip={() => {
                    // if (!isMuted) flipSound.current.play();
                  }}
                  style={{
                    margin: "0 ",
                    transform: `scale(${zoom})`,
                    borderRadius: "10px",
                    marginTop: "40px",
                    
                  }}
                >
                  {pdfPages.map((pageImage, index) => (
                    <div key={index} className="flipbook-page">
                      <img
                        src={pageImage}
                        alt={`Page ${index + 1}`}
                        onLoad={(e) => {
                          const { naturalWidth, naturalHeight } = e.target;
                          setPageDimensions({
                            width: naturalWidth,
                            height: naturalHeight,
                          });
                        }}
                      />
                    </div>
                  ))}
                </HTMLFlipBook>
              ) :(
                <div>
                {progress > 0 && progress < 100 && (
                 <div className="upload-progress-bar loader-spinner-custom mt-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                 <div style={{ width: 200, height: 200 }}>
                   <div style={{ width: 100, height: 100,justifyContent: 'center',alignContent:'center' }}>
                     <CircularProgressbar
                       value={progress}
                       text={`${progress}%`}
                       styles={buildStyles({
                         pathColor: "#de3744",
                         textColor: "#de3744",
                       })}
                     />
                   </div>
                 </div>
               </div>
               
                )}
                </div>
              )}
          

              <div className="icons mt-5 text-center icon-color">
                <HiChevronDoubleLeft
                  className="h-2 w-2 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleFirstPage}
                />
                <HiChevronLeft
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handlePreviousPage}
                />
                <HiChevronRight
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleNextPage}
                />
                <HiChevronDoubleRight
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleLastPage}
                />


                <MdZoomIn
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleZoomIn}
                />
                <MdZoomOut
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleZoomOut}
                />
                {isFullscreen ? (
                  <BiExitFullscreen
                    className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                    onClick={handleExitFullscreen}
                  />
                ) : (
                  <MdFullscreen
                    className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                    onClick={handleEnterFullscreen}
                  />
                )}
                <FaDownload
                  className="h-6 w-6 sm:h-8 sm:w-8 me-4 icon-color"
                  onClick={handleDownload}
                />
               
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
