import React, { useEffect, useState, useRef } from "react";
import Content from "../../layout/content/Content";
import { Col, Row, Icon } from "../../components/Component";
import "../../css/style.css";
import NoResult from "../../images/No Result.png";
import NoCategory from "../../images/No_Category.png";
import NoGeners from "../../images/No_Geners.png";
import NoServices from "../../images/No_Services.png";
import { Card, CardTitle, Modal, ModalBody, ModalHeader } from "reactstrap";
import { SlickArrowLeft, SlickArrowRight } from "../../components/partials/slick/SlickComponents";
import book from "../../jsonfiles/Booking.json";
import dj from "../../jsonfiles/Dj.json";
import { Links } from "../../jsonfiles/Links";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const Professionalsummery = ({ companyData }) => {
  const activeVariable = getActiveEnvironmentVariable();

  const [videoOpen, setVideoOpen] = useState(false);
  const [yTVideoId, setYTVideoId] = useState([]);
  const videoRef = useRef(null);
  const defaultImg = Links?.[activeVariable]?.Home?.[0]?.url4;

  useEffect(() => {
    if (companyData?.youtube_links) {
      try {
        const getYtId = JSON.parse(companyData.youtube_links);
        setYTVideoId(getYtId || []);
      } catch (error) {
        console.error("Error parsing YouTube links:", error);
        setYTVideoId([]);
      }
    } else {
      setYTVideoId([]);
    }
  }, [companyData]);

  const settings2 = {
    className: "slider-init plan-list",
    slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    slide: "li",
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
  };

  const renderList = (items, type) => {
    if (items.length > 0) {
      return items.map((item, index) => (
        <div key={index} className="w-100 list-group-item border rounded-3 my-1 px-2">
          <span className="bi bi-dot fw-medium">{item.trim()}</span>
        </div>
      ));
    } else {
      return (
        <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
          {type === "Categories" ? (
            <img src={NoCategory} alt={`No ${type} Found`} className="mb-1" style={{ width: "80px" }} />
          ) : type === "Services" ? (
            <img src={NoServices} alt={`No ${type} Found`} className="mb-1" style={{ width: "80px" }} />
          ) : type === "Genres" ? (
            <img src={NoGeners} alt={`No ${type} Found`} className="mb-1" style={{ width: "80px" }} />
          ) : (
            ""
          )}
          {/* <span className="bi bi-dot fw-medium">{`No ${type} Found`}</span> */}
        </div>
      );
    }
  };

  return (
    <div id="Professionalsummery">
      <Content className="">
        <Row className="g-gs flex-lg-row-reverse ps-1 justify-center align-center">
          <Col lg={4} className="justify-center">
            <div
              className="video w-70"
              onClick={(ev) => {
                ev.preventDefault();
                setVideoOpen(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                className="video-poster h-300px w-100 object-fit-cover"
                src={companyData?.profile_image_path || defaultImg}
                alt={dj?.[activeVariable]?.section8?.map((item) => item?.alt3)}
              />
              <a className="video-play popup-video" href="#video">
                <Icon name="play"></Icon>
                <span>Watch All Youtube Videos</span>
              </a>
            </div>
          </Col>
          <Col lg={8} className="pt-1">
            <div className="product-details entry me-xxl-3">
              <h3>{book?.[activeVariable]?.section2?.map((item) => item?.text1)}</h3>
              <p>{companyData?.uniqueness || "-------"}</p>
              <Row className="mb-4 justify-center">
                <Col sm={4} size={"auto"} className="p-0 justify-center p-1">
                  <div className="cardui">
                    <div className="text">
                      <span className="text-center text-white fs-3 border-bottom pb-1">
                        {book?.[activeVariable]?.section2?.map((item) => item?.text2)}
                        {/* {book?.[activeVariable]?.section4?.map((item) => item?.text2)} */}
                      </span>
                      <div
                        className="d-flex flex-column overflow-auto m-2 align-center"
                        style={{ height: "190px", scrollbarWidth: "none" }}
                      >
                        {renderList(companyData?.category || [], "Categories")}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={4} size={"auto"} className="p-0 justify-center p-1">
                  <div className="cardui">
                    <div className="text">
                      <span className="text-center text-white fs-3 border-bottom pb-1">
                        {book?.[activeVariable]?.section2?.map((item) => item?.text3)}
                      </span>
                      <div
                        className="d-flex flex-column overflow-auto m-2 align-center"
                        style={{ height: "190px", scrollbarWidth: "none" }}
                      >
                        {renderList(companyData?.services || [], "Services")}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={4} size={"auto"} className="p-0 justify-center p-1">
                  <div className="cardui">
                    <div className="text">
                      <span className="text-center text-white fs-3 border-bottom pb-1">
                        {book?.[activeVariable]?.section2?.map((item) => item?.text4)}
                      </span>
                      <div
                        className="d-flex flex-column overflow-auto m-2"
                        style={{ height: "190px", scrollbarWidth: "none" }}
                      >
                        {renderList(companyData?.genres || [], "Genres")}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Modal size="xl" isOpen={videoOpen} toggle={() => setVideoOpen(!videoOpen)}>
          <ModalHeader
            className="justify-between"
            toggle={() => setVideoOpen(!videoOpen)}
            close={
              <button className="close ml-auto" onClick={() => setVideoOpen(!videoOpen)}>
                <Icon name="cross" />
              </button>
            }
          >
            Watch Youtube Videos
          </ModalHeader>
          <ModalBody className="p-0 overflow-auto" style={{ height: "600px" }}>
            <Row className="g-0  h-100">
              {yTVideoId.length > 0 && yTVideoId ? (
                yTVideoId.map((item, idx) => (
                  <Col lg={4} key={idx} className="text-center">
                    <iframe
                      src={`https://www.youtube.com/embed/${item.link}?mute=1&loop=1&playlist=${item.link}`}
                      title={`YouTube video player - ${item.link}`}
                      allow="encrypted-media; picture-in-picture"
                      allowFullScreen
                      style={{
                        height: "200px",
                        border: "none",
                      }}
                      className="rounded-4 w-75 px-1 py-1"
                    ></iframe>
                    <span className="px-1 lead-text">{item?.title}</span>
                  </Col>
                ))
              ) : (
                <Col lg={4} className="align-center justify-center d-flex w-100">
                  <Card className="p-2">
                    <div className="justify-center  ">
                      <img src={NoResult} alt="No-Result" height="200px" />
                    </div>
                  </Card>
                </Col>
              )}
            </Row>
          </ModalBody>
        </Modal>
      </Content>
    </div>
  );
};

export default Professionalsummery;
