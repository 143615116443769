import React from "react";
import { Block, BlockTitle, Icon, PreviewCard, Row } from "../../components/Component";
import { Button, Card, Col } from "reactstrap";
import { useNavigate } from "react-router";
import { Links } from "../../jsonfiles/Links";
import Home from "../../jsonfiles/Home.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import djonly from "../../jsonfiles/Djonly.json";

const ClientSection = () => {
  const activeVariable = getActiveEnvironmentVariable();

  const DjSectionImg = Links?.[activeVariable]?.Home?.[0]?.url6;
  const DjSectionImgalt = Links?.[activeVariable]?.Home?.[0]?.url6;
  const navigate = useNavigate();

  return (
    <div>
      <Block className="border border-1">
        <PreviewCard className="mx-1 my-1 border-0">
          <Row>
            <Col lg="8" className="">
              <h3 className="fs-1 lead-text mb-3">
                {Home?.[activeVariable]?.section4?.map((item) => item?.title)}{" "}
                <span className="text-primary fw-medium">{process.env.REACT_APP_TITLE}</span>
              </h3>
              <p>{Home?.[activeVariable]?.section4?.map((item) => item?.Description)}</p>

              <div className="border-1 border-primary leaflet-bar m-3 p-3 shadow">
                <div>
                  <span className="fs-3 lead-text">
                    {Home?.[activeVariable]?.section4?.map((item) => item?.leadtext)} {process.env.REACT_APP_TITLE}?
                  </span>
                </div>
                <div className="row justify-between px-sm-1 px-0">
                  <div className="col-sm-5 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text1)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text1des)}</p>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text2)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text2des)}</p>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text3)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text3des)}</p>
                  </div>

                  <div className="col-sm-7 col-auto p-0">
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text4)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text4des)}</p>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text5)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text5des)}</p>
                    <div className="d-flex">
                      <span className="fs-3">
                        <Icon name="play"></Icon>
                      </span>
                      <p className="align-center fs-5">
                        {Home?.[activeVariable]?.section4?.map((item) => item?.text6)}
                      </p>
                    </div>
                    <p>{Home?.[activeVariable]?.section4?.map((item) => item?.text6des)}</p>
                  </div>
                </div>
              </div>
              {/* <div className="justify-end px-2 my-1">
                <Button className="text-white bg-primary" color="primary" onClick={() => navigate("/client-section")}>
                  {Home?.[activeVariable]?.section4?.map((item) => item?.buttontext)}
                </Button>
              </div> */}
            </Col>
            <Col lg="4">
              <div className="justify-center mt-3 mt-lg-0">
                <img src={DjSectionImg} alt={DjSectionImgalt} className="img-fluid object-fit-cover h-370px" />
              </div>
            </Col>
          </Row>
        </PreviewCard>
      </Block>
    </div>
  );
};

export default ClientSection;
