import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTheme } from "../provider/Theme";
import User from "./dropdown/user/User";
import AppDropdown from "./dropdown/app/App";
import { createcontext } from "../Index";
import LogoFetch from "./LogoFetch";
import headerData from "../../jsonfiles/Header.json"; // Import the header.json
import { getAllCities, getsitesetting } from "../../http/get/getApi";
import { useQuery } from "react-query";
import Home from "../../jsonfiles/Home.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const AppHeader = ({ fixed, className, app }) => {
  const getsitesetting = async () => {
    const requestOptions = {
      method: "POST",
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, requestOptions);

      if (!response.ok) {
        const errorInfo = await response.json();
        const error = new Error("An error occurred while fetching site settings");
        error.info = errorInfo;
        error.code = response.status;
        throw error;
      }

      const res = await response.json();

      return res?.data?.site_settings || null;
    } catch (error) {
      console.error("Error fetching site settings:", error);
      throw error;
    }
  };
  const handleLoginClick = async () => {
    try {
      const response = await getsitesetting();
      const data = response;
      if (data) {
        setAuthTokenCookie(data);
      }
    } catch (error) {
      console.error("Error:", error.message, error.info);
    }
  };
  const setAuthTokenCookie = (data) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    if (data) {
      Cookies.set("site_Setting", JSON.stringify(data), { expires: expires });
    }
  };

  useEffect(() => {
    handleLoginClick();
  }, []);
  const theme = useTheme();
  const [headerLinks, setHeaderLinks] = useState([]);
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
    "bg-white": true,
    "text-dark": true,
  });
  
  
  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    const activeEnv = getActiveEnvironmentVariable();
    if (activeEnv && headerData.links[activeEnv]) {
      setHeaderLinks(headerData.links[activeEnv]);
    } else {
      console.warn("No matching header links found for the active environment.");
    }
  }, []);

  const cookieVariable = () => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // Expires in 24 hours
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };

  const authTokenChk = Cookies.get("authToken");
  const currentUrl = window.location.pathname || null;

  const { heroSection, setHeroSection } = useContext(createcontext);

  // Ensure the hero section is visible initially
  useEffect(() => {
    setHeroSection(true);
  }, []);

 

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap d-flex">
          <div className="nk-header-app-name d-flex align-items-center" style={{ width: "150px" }}>
            <div className="nk-header-app-logo h-100 w-100">
              <Link to="/" className="nav-link text-dark w-100"></Link>
              <LogoFetch />
            </div>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav d-flex list-unstyled mb-0">
              {headerLinks.map((link, index) => (
                <li key={index} className="nav-item me-3 d-none d-md-block">
                  <Link
                    to={link.name === "Blog" ? process.env.REACT_APP_BLOG_URL : link.path}
                    className={currentUrl === link.path ? "text-primary nav-link" : "infoSection"}
                    // target={link.name === "Blog" ? "_blank	" : "_self"}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
              {authTokenChk ? (
                <li className="nav-item">
                  <User />
                </li>
              ) : (
                <li className="nav-item d-none d-md-block">
                  <Link to={`${process.env.REACT_APP_ACCOUNT_URL}login`} className="nav-link" onClick={cookieVariable}>
                    <button className="btn btn-primary">{Home?.CRM?.section1.map((item) => item.title5)}</button>
                  </Link>
                </li>
              )}
              <li className="nav-item me-3 d-md-none">
                <AppDropdown />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppHeader;
