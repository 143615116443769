import React, { useEffect, useState } from "react";
import { Block } from "../../../components/Component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "../../../css/style.css";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Links } from "../../../jsonfiles/Links";
import bussiness from "../../../jsonfiles/BusinessPortal.json";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";
import { useQuery } from "react-query";
import { getCountryData } from "../../../http/get/getApi";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};

const BusinessPortal = () => {
  // contact number operation
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const activeVariable = getActiveEnvironmentVariable();

  // based on environment variables
  const showIpdm = process.env.REACT_APP_DISPLAY_IPDM === "true";
  const showPhotography = process.env.REACT_APP_DISPLAY_PHOTOGRAPHY === "true";
  const showConstruction = process.env.REACT_APP_DISPLAY_CONSTRUCTION === "true";

  const img1 = Links?.[activeVariable]?.BusinessPortal?.[0]?.url1;
  const img2 = Links?.[activeVariable]?.BusinessPortal?.[2]?.url3;
  const img3 = Links?.[activeVariable]?.BusinessPortal?.[1]?.url2;
  const img4 = Links?.[activeVariable]?.BusinessPortal?.[3]?.url4;
  const img5 = Links?.[activeVariable]?.BusinessPortal?.[4]?.url5;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [formActive, setFormActive] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
    country_id: "",
  });

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const handleContact = (value) => {
    setFormActive(value);
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      country_id: "",
      message: "",
    });
    reset({});
  };
  const handleSuccess = () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Information Sent Successfully",
      text: "We will reach you soon!",
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const {
    data: countryListData,
    isLoading: countryListLoading,
    isError: countryListError,
  } = useQuery({
    queryKey: ["get-countrydata-list"],
    queryFn: () => getCountryData(),
    staleTime: Infinity,
  });

  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };
  console.log("formDataasd", formData);

  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
    resetForm();
  };

  const onSubmit = (data) => {};

  const onSubmit1 = (data) => {};
  useEffect(() => {
    setFormData({ ...formData, country_id: countryListData?.data?.countries?.[0]?.id });
  }, [countryListData]);

  const currentDomain = window.location.hostname.includes(".in")
    ? 101 // India (+91)
    : window.location.hostname.includes(".com")
    ? 231 // USA (+1)
    : window.location.hostname === "localhost"
    ? 101
    : "";

  const renderForm = () => {
    return (
      <div className="h-100 w-100 m-5">
        <form onSubmit={handleSubmit(FormSubmit)}>
          <Row>
            <Row lg={12}>
              <div className="form-group col-lg-6 " style={{ marginTop: "2px" }}>
                <div className="form-group w-75">
                  <div className="form-control-wrap my-2 ">
                    <label htmlFor="event_message">
                      Full Name <Required />
                    </label>
                    <input
                      className="form-control "
                      type="text"
                      id="name"
                      autoComplete="name"
                      placeholder="Enter Full Name"
                      {...register("name", {
                        required: "This field is required",
                        pattern: {
                          value: /^[A-Za-z\s]+$/,
                          message: "Only alphabets and spaces are allowed",
                        },
                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      value={formData.name}
                      onChange={(e) => {
                        setFormData({ ...formData, name: e.target.value });
                        clearErrors(e.target.name);
                      }}
                      maxLength={30}
                    />
                    {errors.name && <span className="invalid">{errors.name.message}</span>}
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-6 w-50">
                <div className="form-control-wrap my-1 ">
                  <label htmlFor="event_message">
                    Email <Required />
                  </label>
                  <input
                    className="form-control border-0 text-white "
                    style={{ backgroundColor: "#5A5A5A " }}
                    type="text"
                    id="email"
                    autoComplete="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value:
                          // eslint-disable-next-line
                          /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Enter a valid email address",
                      },
                      maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                    })}
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                      clearErrors(e.target.email);
                    }}
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </div>
            </Row>

            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap ">
                  <label htmlFor="event_message" className="fs-6">
                    Contact Number <Required />
                  </label>
                  <div className="d-flex w-95 ">
                    <select
                      name="country_id"
                      className="text-white border-0 form-control w-20 me-1"
                      style={{ backgroundColor: "#5A5A5A" }}
                      value={formData.country_id}
                      onChange={(e) => {
                        setFormData({ ...formData, country_id: e.target.value });
                      }}
                    >
                      {countryListLoading ? (
                        <div className="mx-1 align-center">
                          <Spinner size="sm" color="light" />
                          <span className="px-1">Loading...</span>
                        </div>
                      ) : countryListError ? (
                        <div>Error loading data. Please try again.</div>
                      ) : countryListData?.data?.countries?.length ? (
                        countryListData?.data?.countries

                          .filter((country) => country.id === currentDomain)
                          .map((item, idx) => (
                            <option
                              key={idx}
                              value={item?.id}
                              className="text-white border-0 form-control w-20"
                              style={{ backgroundColor: "#5A5A5A" }}
                            >
                              +{item?.phonecode}
                            </option>
                          ))
                      ) : (
                        <div>No Record Found</div>
                      )}
                    </select>
                    <input
                      className="form-control border-0 text-white  w-95 "
                      style={{ backgroundColor: "#5A5A5A" }}
                      type="number"
                      id="phone_number"
                      autoComplete="phone_number"
                      onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                      placeholder="Enter Contact Number"
                      {...register("phone_number", {
                        required: "This field is required",
                        minLength: {
                          value: 10,
                          message: "Please enter at least 10 digits",
                        },
                      })}
                      value={formData.phone_number}
                      onChange={(e) => {
                        if (e.target.value.length <= 15) {
                          setFormData({ ...formData, phone_number: e.target.value });
                        } else {
                          blockNumbers();
                        }
                      }}
                    />
                  </div>
                  {errors.phone_number && (
                    <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                      {errors.phone_number.message}
                    </span>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={12} className="mt-2">
              <div className="form-group">
                <div className="form-control-wrap mt-1">
                  <label htmlFor="message" className="fs-6">
                    Message
                  </label>
                  <textarea
                    className="form-control border-0 text-white h-100px w-95"
                    style={{ backgroundColor: "#5A5A5A" }}
                    type="text"
                    id="message"
                    placeholder="Enter Event Message"
                    {...register("message", {
                      pattern: {
                        message: "Only alphabets and spaces are allowed",
                      },
                      minLength: {
                        value: 5,
                        message: "Please enter at least 5 characters",
                      },
                    })}
                    maxLength={500}
                    value={formData.message}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                  />
                  {errors.message && <span className="invalid">{errors.message.message}</span>}
                </div>
              </div>
            </Col>
            <Col lg={12}>
              <div className="form-group">
                <div className="form-control-wrap mt-2">
                  {loading ? (
                    <Button disabled color="primary" className="justify-center w-95 rounded">
                      <Spinner size="sm" />
                      <span> Loading... </span>
                    </Button>
                  ) : (
                    <Button color="primary" className="justify-center w-95 rounded" type="submit">
                      Send Message
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    );
  };
  const FormSubmit = async (form) => {
    const { name, email, country_id, phone_number, message } = form;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("country", country_id);
      formData.append("contact", phone_number);
      formData.append("message", message);
      const contactdata = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      const contaactinfo = await contactdata.json();
      if (contaactinfo && contaactinfo.status) {
        const submittedData = contaactinfo?.data;

        // toast.success("We will be reach soon!");
        resetForm();
        setFormActive("");
        handleSuccess();
      } else {
        toast.error(contaactinfo?.errors?.[0]);
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
    toggleModal();
  };
  const blockNumbers = () => {};
  return (
    <>
      <Block className="">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper2 "
        >
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img3}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "725px" }}
              />
              <div
                className=" responsive-text position-absolute   mx-0 px-md-5 px-0 h-100 text-white w-100"
                style={{ zIndex: 1 }}
              >
                <div className="flex-column  w-100 my-5">
                  <div className="my-0 m-5">
                    <span className="fs-1 d-none d-lg-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title1)}
                    </span>
                    <span className="responsive-font d-lg-none ">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title1)}
                    </span>
                  </div>
                  <div className="mb-2 align-center  my-0 m-5">
                    <span className="responsive-fonttext fs-6 w-100 d-none d-lg-block align-center">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text1)}
                    </span>
                    <span className="responsive-fonttext w-100 d-lg-none align-center justify-content-center">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text1)}
                    </span>
                  </div>
                  <div className=" my-0">
                    <span className="fs-5 w-100 d-none d-lg-block mx-5">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => {
                          //  handleContact(1)
                          toggleModal();
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>

                    <span className="fs-7  d-lg-none">
                      <Button
                        className="w-30 px-1 py-1 justify-center rounded-3 mx-5"
                        color="primary"
                        onClick={() => {
                          toggleModal();
                          // handleContact(1);
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="w-60  mx-5">{formActive === 1 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img1}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "725px" }}
              />
              <div
                className="responsive-text position-absolute   mx-0 px-md-5 px-0 h-100 text-white w-100 "
                style={{ zIndex: 1 }}
              >
                <div className="flex-column  w-100 my-5">
                  <div className="my-0 m-5">
                    <span className="fs-1 d-none d-lg-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title2)}
                    </span>
                    <span className=" responsive-font d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title2)}
                    </span>
                  </div>
                  <div className="m-5 my-0">
                    <span className="responsive-fonttext fs-6 w-100 d-none d-lg-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text2)}
                    </span>
                    <span className="responsive-fonttext w-100 d-lg-none text-center">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text2)}
                    </span>
                  </div>

                  <div className=" my-0">
                    <span className="fs-5 w-100 d-none d-lg-block mx-5">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => {
                          //  handleContact(1)
                          toggleModal();
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>

                    <span className="fs-7  d-lg-none">
                      <Button
                        className="w-30 px-1 py-1 justify-center rounded-3 mx-5"
                        color="primary"
                        onClick={() => {
                          toggleModal();
                          // handleContact(1);
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="w-60  mx-5">{formActive === 2 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img2}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "725px" }}
              />
              <div
                className="responsive-text position-absolute   mx-0 px-md-5 px-0 h-100 text-white w-100"
                style={{ zIndex: 1 }}
              >
                <div className="flex-column  w-100 my-5">
                  <div className="my-0 m-5">
                    <span className="fs-1 d-none d-lg-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title3)}
                    </span>
                    <span className="responsive-font d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title3)}
                    </span>
                  </div>
                  <div className="m-5 my-0 ">
                    <span className="responsive-fonttext fs-6 w-100 d-none d-lg-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text3)}

                      <ul className="ps-5 fs-7" style={{ listStyleType: "disc" }}>
                        <li>{bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.1"])}</li>
                        <li>{bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.2"])}</li>
                        <li>{bussiness?.[activeVariable]?.section1?.map((item) => item?.["text3.3"])}</li>
                      </ul>
                    </span>
                    <span className="responsive-fonttext w-100 d-lg-none text-center">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.textsub3)}
                    </span>
                  </div>

                  <div className=" my-0">
                    <span className="fs-5 w-100 d-none d-lg-block mx-5">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => {
                          //  handleContact(1)
                          toggleModal();
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>

                    <span className="fs-7  d-lg-none">
                      <Button
                        className="w-30 px-1 py-1 justify-center rounded-3 mx-5"
                        color="primary"
                        onClick={() => {
                          toggleModal();
                          // handleContact(1);
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="w-60  mx-5">{formActive === 3 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img4}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "725px" }}
              />
              <div
                className="responsive-text position-absolute  mx-0 px-md-5 px-0  text-white w-100"
                style={{ zIndex: 1 }}
              >
                <div className="flex-column  w-100 my-5">
                  <div className="my-0 m-5">
                    <span className="fs-1 d-none d-lg-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title4)}
                    </span>
                    <span className="responsive-font d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title4)}
                    </span>
                  </div>
                  <div className="m-5 my-0">
                    <span className="responsive-fonttext fs-6 w-100 d-none d-lg-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text4)}
                    </span>
                    <span className="responsive-fonttext w-100 h-100 d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text4)}
                    </span>
                  </div>

                  <div className=" my-0">
                    <span className="fs-5 w-100 d-none d-lg-block mx-5">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => {
                          //  handleContact(1)
                          toggleModal();
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>

                    <span className="fs-7  d-lg-none">
                      <Button
                        className="w-30 px-1 py-1 justify-center rounded-3 mx-5"
                        color="primary"
                        onClick={() => {
                          toggleModal();
                          // handleContact(1);
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="w-60  mx-5">{formActive === 4 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div style={{ position: "relative" }}>
              <img
                src={img5}
                className="opacity-75"
                style={{ filter: "brightness(1.5) opacity(0.5)", objectFit: "cover", width: "100%", height: "725px" }}
              />
              <div
                className="responsive-text position-absolute  mx-0 px-md-5 px-0  text-white w-100"
                style={{ zIndex: 1 }}
              >
                <div className="flex-column  w-100 my-5">
                  <div className="my-0 m-5">
                    <span className="fs-1 d-none d-lg-block mb-3">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title5)}
                    </span>
                    <span className="responsive-font d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.title5)}
                    </span>
                  </div>
                  <div className="m-5 my-0">
                    <span className="responsive-fonttext fs-6 w-100 d-none d-lg-block">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text5)}
                    </span>
                    <span className="responsive-fonttext w-100 h-100 d-lg-none">
                      {bussiness?.[activeVariable]?.section1?.map((item) => item?.text5)}
                    </span>
                  </div>

                  <div className=" my-0">
                    <span className="fs-5 w-100 d-none d-lg-block mx-5">
                      <Button
                        className="w-150px justify-center rounded-3 py-2"
                        color="primary"
                        onClick={() => {
                          //  handleContact(1)
                          toggleModal();
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>

                    <span className="fs-7  d-lg-none">
                      <Button
                        className="w-30 px-1 py-1 justify-center rounded-3 mx-5"
                        color="primary"
                        onClick={() => {
                          toggleModal();
                          // handleContact(1);
                        }}
                      >
                        {bussiness?.[activeVariable]?.section1?.map((item) => item?.buttontext)}
                      </Button>
                    </span>
                  </div>
                </div>
                <div className="w-60  mx-5">{formActive === 5 && renderForm()}</div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="swiper2 swiperss d-lg-block"
        >
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img3} />
          </SwiperSlide>

          <SwiperSlide className={`swiper-slidess`}>
            <img src={img1} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img2} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img4} />
          </SwiperSlide>
          <SwiperSlide className={`swiper-slidess`}>
            <img src={img5} />
          </SwiperSlide>
        </Swiper>

        <Modal isOpen={modal} toggle={toggleModal} centered size="lg">
          <div className="modal-header">
            <h5 className="modal-title">Contact Form</h5>
            <button type="button" className="btn-close" onClick={toggleModal}></button>
          </div>
          <div className="modal-body">
            
            <form onSubmit={handleSubmit(FormSubmit)}>
              <Row>
                
                <Col lg={6} className="justify-center">
                  <div className="form-group w-100">
                    <div className="form-control-wrap my-2 ">
                      <label htmlFor="event_message">
                        Full Name <Required />
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        autoComplete="name"
                        placeholder="Enter Full Name"
                        {...register("name", {
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only alphabets and spaces are allowed",
                          },
                          minLength: {
                            value: 3,
                            message: "Please enter at least 3 characters",
                          },
                        })}
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({ ...formData, name: e.target.value });
                          clearErrors(e.target.name);
                        }}
                        maxLength={30}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="justify-center">
                  <div className="form-group w-100">
                    <div className="form-control-wrap my-2 ">
                      <label htmlFor="event_message">
                        Email <Required />
                      </label>
                      <input
                        className="form-control "
                        type="text"
                        id="email"
                        autoComplete="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value:
                              // eslint-disable-next-line
                              /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                            message: "Enter a valid email address",
                          },
                          maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                        })}
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                          clearErrors(e.target.email);
                        }}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="form-group w-100">
                    <div className="form-control-wrap ">
                      <label htmlFor="event_message" className="fs-6">
                        Contact Number <Required />
                      </label>
                      <div className="d-flex ">
                        <select
                          name="country_id"
                          className="text-fade border-1 form-control w-20 me-1"
                          style={{ backgroundColor: "white" }}
                          value={formData.country_id}
                          onChange={(e) => {
                            setFormData({ ...formData, country_id: e.target.value });
                          }}
                        >
                          {countryListLoading ? (
                            <div className="mx-1 align-center">
                              <Spinner size="sm" color="light" />
                              <span className="px-1">Loading...</span>
                            </div>
                          ) : countryListError ? (
                            <div>Error loading data. Please try again.</div>
                          ) : countryListData?.data?.countries?.length ? (
                            countryListData?.data?.countries

                              .filter((country) => country.id === currentDomain)
                              .map((item, idx) => (
                                <option
                                  key={idx}
                                  value={item?.id}
                                  className="text-fade border-1 form-control w-20"
                                  style={{ backgroundColor: "white" }}
                                >
                                  +{item?.phonecode}
                                </option>
                              ))
                          ) : (
                            <div>No Record Found</div>
                          )}
                        </select>
                        <input
                          className="form-control border-1 text-fade w-80"
                          style={{ backgroundColor: "white" }}
                          type="number"
                          id="phone_number"
                          onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                          placeholder="Enter Contact Number"
                          {...register("phone_number", {
                            required: "This field is required",
                            minLength: {
                              value: 10,
                              message: "Please enter at least 10 digits",
                            },
                          })}
                          value={formData.phone_number}
                          onChange={(e) => {
                            const value = e.target.value;
                            // Update form data and clear errors if the length is valid
                            if (value.length <= 15) {
                              setFormData({ ...formData, phone_number: value });

                              // Clear error if the value meets the minimum length requirement
                              if (value.length >= 10) {
                                clearErrors("phone_number");
                              }
                            } else {
                              blockNumbers();
                            }
                          }}
                        />
                      </div>
                      {errors.phone_number && (
                        <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                          {errors.phone_number.message}
                        </span>
                      )}
                    </div>
                  </div>
                </Col>

                <Col lg={12} className="mt-2">
                  <div className="form-group w-100">
                    <div className="form-control-wrap mt-1">
                      <label htmlFor="message" className="fs-6">
                        Message
                      </label>
                      <textarea
                        className="form-control border-1 text-fade h-100px"
                        style={{ backgroundColor: "white" }}
                        id="message"
                        placeholder="Enter Event Message"
                        {...register("message", {
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Za-z\s]*$/,
                            message: "Only alphabets and spaces are allowed",
                          },
                          minLength: {
                            value: 5,
                            message: "Please enter at least 5 characters",
                          },
                        })}
                        maxLength={500}
                        value={formData.message}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFormData({ ...formData, message: value });

                          // Clear error if the value is valid (matches pattern and length >= 5)
                          if (/^[A-Za-z\s]*$/.test(value) && value.length >= 5) {
                            clearErrors("message");
                          }
                        }}
                      />
                      {errors.message && <span className="invalid">{errors.message.message}</span>}
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="form-group">
                    <div className="form-control-wrap mt-2">
                      {loading ? (
                        <Button disabled color="primary" className="justify-center w-95 rounded">
                          <Spinner size="sm" />
                          <span> Loading... </span>
                        </Button>
                      ) : (
                        <Button color="primary" className="justify-center w-95 rounded" type="submit">
                          Send Message
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Modal>
      </Block>
    </>
  );
};

export default BusinessPortal;
