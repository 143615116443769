import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import rf from "../../jsonfiles/RefundCancellation.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import privacypolicy from "../../jsonfiles/Privacypolicy.json";

const TermsCondition = () => {
  const activeVariable = getActiveEnvironmentVariable();

  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>{rf?.[activeVariable]?.section1?.map((item) => item?.title1)}</Card.Title>
          <p>
            At <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title2)}</strong>
            {rf?.[activeVariable]?.section1?.map((item) => item?.title3)}
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title22)}</strong>
              <p>
                {rf?.[activeVariable]?.section1?.map((item) => item?.title4)}
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title5)}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong> {rf?.[activeVariable]?.section1?.map((item) => item?.title23)}</strong>
              <p>
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title24)}</strong>
                <br />
                {rf?.[activeVariable]?.section1?.map((item) => item?.title6)}{" "}
                <strong>" {rf?.[activeVariable]?.section6?.map((item) => item?.title3)}"</strong> "{" "}
                {rf?.[activeVariable]?.section6?.map((item) => item?.title4)}"
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title25)}</strong>
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title7)}
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title26)}</strong>
                <br />
                {rf?.[activeVariable]?.section1?.map((item) => item?.title27)}
              </p>
              <p>
                <strong> {rf?.[activeVariable]?.section1?.map((item) => item?.title28)}</strong>
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title8)}
              </p>

              <p>
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title9)}</strong>
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title10)}
                <ul>
                  <li>
                    <strong> {rf?.[activeVariable]?.section1?.map((item) => item?.title29)}</strong>
                    {rf?.[activeVariable]?.section1?.map((item) => item?.title30)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title31)}</strong>

                    {rf?.[activeVariable]?.section1?.map((item) => item?.title11)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title1)}</strong>
              <p>
                <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title2)}</strong>
                <br />
                {rf?.[activeVariable]?.section2?.map((item) => item?.title3)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title4)}</strong>
                    {rf?.[activeVariable]?.section2?.map((item) => item?.title5)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title6)}</strong>
                    {rf?.[activeVariable]?.section2?.map((item) => item?.title7)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title8)}</strong>
                    {rf?.[activeVariable]?.section2?.map((item) => item?.title9)}
                  </li>
                </ul>
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title10)}</strong>
                <br />
                {rf?.[activeVariable]?.section2?.map((item) => item?.title11)}
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title12)}</strong>
                <br />
                {rf?.[activeVariable]?.section2?.map((item) => item?.title13)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title14)}</strong>
                    {rf?.[activeVariable]?.section2?.map((item) => item?.title15)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title16)}</strong>
                    {rf?.[activeVariable]?.section2?.map((item) => item?.title17)}
                  </li>
                </ul>
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section2?.map((item) => item?.title18)}</strong>
                <br />
                {rf?.[activeVariable]?.section2?.map((item) => item?.title19)}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title1)}</strong>
              <p>
                <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title2)}</strong>
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title12)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title3)}</strong>
                    {rf?.[activeVariable]?.section3?.map((item) => item?.title4)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title5)}</strong>

                    {rf?.[activeVariable]?.section1?.map((item) => item?.title21)}
                  </li>
                </ul>
              </p>
              <p>
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title13)}</strong>
                <br />

                {rf?.[activeVariable]?.section1?.map((item) => item?.title14)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title6)}</strong>
                    {rf?.[activeVariable]?.section3?.map((item) => item?.title7)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section3?.map((item) => item?.title8)}</strong>

                    {rf?.[activeVariable]?.section1?.map((item) => item?.title15)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section4?.map((item) => item?.title1)}</strong>
              <p>
                {rf?.[activeVariable]?.section1?.map((item) => item?.title16)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section4?.map((item) => item?.title2)}</strong>
                    {rf?.[activeVariable]?.section4?.map((item) => item?.title3)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section4?.map((item) => item?.title4)}</strong>
                    {rf?.[activeVariable]?.section4?.map((item) => item?.title5)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section5?.map((item) => item?.title1)}</strong>
              <p>
                {rf?.[activeVariable]?.section5?.map((item) => item?.title2)}{" "}
                <strong>{rf?.[activeVariable]?.section5?.map((item) => item?.title3)}</strong>
                {rf?.[activeVariable]?.section5?.map((item) => item?.title4)}
                <ul>
                  <li>
                    <strong>{rf?.[activeVariable]?.section5?.map((item) => item?.title5)}</strong>
                    {rf?.[activeVariable]?.section5?.map((item) => item?.title6)}
                  </li>
                  <li>
                    <strong>{rf?.[activeVariable]?.section5?.map((item) => item?.title7)}</strong>
                    {rf?.[activeVariable]?.section5?.map((item) => item?.title8)}
                  </li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section5?.map((item) => item?.title9)}</strong>
              <p>{rf?.[activeVariable]?.section1?.map((item) => item?.title17)}</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section6?.map((item) => item?.title1)}</strong>
              <p>
                {rf?.[activeVariable]?.section6?.map((item) => item?.title2)}
                <ul>
                  <li>
                    {rf?.[activeVariable]?.section1?.map((item) => item?.title18)}
                    <strong>" {rf?.[activeVariable]?.section6?.map((item) => item?.title3)}"</strong>{" "}
                    {rf?.[activeVariable]?.section6?.map((item) => item?.title4)}
                  </li>
                  <li> {rf?.[activeVariable]?.section6?.map((item) => item?.title5)}</li>
                </ul>
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{rf?.[activeVariable]?.section7?.map((item) => item?.title1)}</strong>
              <p>
                {rf?.[activeVariable]?.section7?.map((item) => item?.title2)}
                <br />
                <strong>{rf?.[activeVariable]?.section1?.map((item) => item?.title19)}</strong>
                <br />
                {rf?.[activeVariable]?.section7?.map((item) => item?.title3)}{" "}
                <a href={`mailto:${privacypolicy?.[activeVariable]?.section21?.[3]?.listinfotext1}`}>
                  {" "}
                  {rf?.[activeVariable]?.section7?.map((item) => item?.title4)}{" "}
                </a>
                <br />
                {rf?.[activeVariable]?.section7?.map((item) => item?.title5)}{" "}
                <a href="tel:+14695540310">{rf?.[activeVariable]?.section7?.map((item) => item?.title6)}</a>
                <br />
                {/* {rf?.[activeVariable]?.section7?.map((item) => item?.title7)} */}
              </p>
            </ListGroup.Item>
          </ListGroup>
          <p className="mt-4">{rf?.[activeVariable]?.section1?.map((item) => item?.title20)}</p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
