import React, { useContext, useEffect } from "react";
import "../../../css/style.css";
import VideoSlider from "../about/VideoSlider";
import ServiceCards from "../about/ServiceCards";
import { Card } from "reactstrap";
import RoleSection from "../../Individual DJ Pages/RoleSection";
import AccordianSection from "../about/AccordianSection";
import { createcontext } from "../../../layout/Index";

const AboutSection = () => {
  const { heroSection, setHeroSection } = useContext(createcontext);

  useEffect(() => {
    setHeroSection(true);
  }, []);
  return (
    <>
      <div>
        <VideoSlider />
      </div>
      <div>
        <ServiceCards />
      </div>
      <Card className="card-bordered mt-0">
        <AccordianSection />
      </Card>
      <Card className="card-bordered mt-0">
        <RoleSection />
      </Card>
    </>
  );
};

export default AboutSection;
